import { createContext, SetStateAction, Dispatch } from "react";

interface List {
    name? : string
    description? : string
    id? : string
    key? : string
    completed : boolean
}

type APIContextTypes = {
    isHamburgerMenu : boolean, 
    setIsHamburgerMenu : Dispatch<SetStateAction<boolean>>,
    list : List[], 
    setList: any;
    remont : any, 
    setRemont : any,
    cookies : any, 
    setCookie: any,
    removeCookie : any,
    dataUser : {name : string, email : string, password : string, id : string},  
    setDataUser : any,
    loadingProfile : any, 
    setLoadingProfile : any,
    contextLS : any,
    setContextLS : any,
    nameLS : any, 
    setNameLS : any,
    specializationLS : any, 
    setSpecializationLS : any,
    authorizationLS : any,
    setAuthorizationLS : any,
    dataRemont : any, 
    setDataRemont : any,
    isShow : any, 
    setIsShow : any,
    selectedFile : any, 
    setSelectedFile : any,
    base64Data : any, 
    setBase64Data : any,
    arrayDescription : any, 
    setArrayDescription : any, 
    specification : any, 
    setSpecification : any, 
    schedule : any, 
    setSchedule : any, 
    noticeBoard : any, 
    setNoticeBoard : any, 
    characteristic : any,
    setCharacteristic : any,
    newMessage : any, 
    setNewMessage : any
};
  
export const APIContext = createContext<APIContextTypes>({
    isHamburgerMenu : false, 
    setIsHamburgerMenu : () => {},
    list : [], 
    setList : () => {},
    remont : {name : "", description : ""}, 
    setRemont : () => {},
    cookies : "", 
    setCookie: () => {},
    removeCookie : () => {},
    dataUser : {name : "", email : "", password : "", id : ""}, 
    setDataUser : () => {},
    loadingProfile : false, 
    setLoadingProfile : () => {},
    contextLS : "",
    setContextLS : () => {},
    nameLS : "", 
    setNameLS : () => {},
    specializationLS : "", 
    setSpecializationLS : () => {},
    authorizationLS : "",
    setAuthorizationLS : () => {},
    dataRemont : [], 
    setDataRemont : () => {},
    isShow : false, 
    setIsShow : () => {},
    selectedFile : null, 
    setSelectedFile : () => {},
    base64Data : "", 
    setBase64Data : () => {},
    arrayDescription : [], 
    setArrayDescription : () => {},
    specification : [], 
    setSpecification : () => {},
    schedule : [],  
    setSchedule : () => {},
    noticeBoard : [],  
    setNoticeBoard : () => {},
    characteristic : [], 
    setCharacteristic : () => {},
    newMessage : false, 
    setNewMessage : () => {}
});
  