import Modal from "react-modal";
import { useAPI } from "../../context";
import axios from "axios";
import { useState } from "react";
import { customStyles } from "../../utils/customStyles";

const ModalEditRepair = ({ indexRemont, isModal, closeModal }: any) => {

  const [error, setIsError] = useState(false);

  const { setRemont, remont, setList, list } = useAPI();
  const { name, description } = remont;

  Modal.setAppElement("#root");

  const saveRemont = () => {

    const data = {
      name: remont.name,
      description: remont.description,
      id: remont.id,
      completed : false
    };
    // @ts-ignore
    axios.put(`${global.REACT_APP_API_URL}/edit/remont`, data)
      .then((response: any) => {
        setList((prevItems: any) => {
          const updatedItems = prevItems.map((item: any, index: number) => {
            if (index === indexRemont) {
              return { ...item, name: name, description: description };
            }
            return item;
          });
          return updatedItems;
        });
        closeModal();
      })
      .catch((error: any) => {
        setIsError(true)
      });
  };

  const remove = () => {
    // @ts-ignore
    axios.delete(`${global.REACT_APP_API_URL}/edit/remont/${remont.id}`)
      .then((response: any) => {
        setList((prevList : any) => prevList.filter((item : any) => item._id !== remont.id))
        closeModal();
    })
      .catch((error: any) => {
        setIsError(true)
    });
  }

  const handleChange = (evt: any) => {
    const value = evt.target.value;
    setRemont({ ...remont, [evt.target.name]: value });
  };

  const completedRemont = () => {
    const data = {
      id: remont.id,
      completed: true
    };
    // @ts-ignore
    axios.put(`${global.REACT_APP_API_URL}/remont/completed`, data)
      .then((response: any) => {
        setList((prevItems: any) => {
          const updatedItems = prevItems.map((item: any, index: number) => {
            if (index === indexRemont) {
              return { ...item, completed: true };
            }
            return item;
          });
  
          const sortedItems = updatedItems.sort((a: any, b: any) => {
            if (a.completed && !b.completed) {
              return -1;
            }
            return 0;
          });
  
          return sortedItems;
        });
  
        closeModal();
      })
      .catch((error: any) => {
        setIsError(true);
      });
  };

  return (
    <Modal isOpen={isModal} style={customStyles} onRequestClose={closeModal}>
      <div className="modalEditRepair">
        {!error ?
        <>
        <div className="modalEditRepair__box">
          <p className="modalEditRepair__text">Klucz</p>
          {list[indexRemont] === undefined ? null : <input className="modalEditRepair__value" value={list[indexRemont].key} type="text" disabled={true}/>}
        </div>
        <p className="creator__text">Edycja wpisu</p>
        <form
          className="creator__form"
          onSubmit={(event) => event.preventDefault()}
        >
          <input
            maxLength={30}
            className="creator__item"
            value={name}
            name="name"
            onChange={(e) => handleChange(e)}
          />
          <textarea
            maxLength={500}
            className="creator__item creator__item--area"
            value={description}
            name="description"
            onChange={(e) => handleChange(e)}
          />
        
          <button
            className="creator__item creator__item--submit"
            onClick={saveRemont}
          >
            Zapisz remont
          </button>
          <button
            className="creator__item creator__item--submitCompleted"
            onClick={completedRemont}
          >
            Zakończ remont
          </button>
          <button
            className="creator__item creator__item--submitRemove"
            onClick={remove}
          >
            Usuń remont
          </button>
        </form>
        </>
        : <p className="creator__text">Coś poszło nie tak spróbuj póżniej</p>}
      </div>
    </Modal>
  );
};

export default ModalEditRepair;
