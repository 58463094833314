import { useEffect, useState } from "react";
import axios from "axios";

const useKey = () => {

  const [loadingKey, setLoadingKey] = useState(false);
  const [key, setKey] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
        try {
          const instance = axios.create({
            withCredentials: true,
          });
          // @ts-ignore
          const response = await instance.get(`${global.REACT_APP_API_URL}/random/key`);
          setKey(response.data.key.split(''));
          setLoadingKey(true);
          
        } catch (error) {
          console.error('Błąd podczas pobierania danych użytkownika:', error);
        }
      }

    fetchData();
  }, []);

  return { loadingKey, key, setLoadingKey, setKey }
};

export default useKey;
