import components from "../../componentList";

const Payment = ({isPayment, dispatch} : any) => {

    const { Section } = components;

    return (
        <div className={isPayment ? "paymentOn" : "paymentOff"}>
            <Section title="Metoda płatności" dispatch={dispatch}>
                <p className="paymentOn__title">Karta debetowa</p>
                <form className="paymentOn__form" onSubmit={(event) => event.preventDefault()}>
                    <label className="paymentOn__name">Numer karty</label>
                    <input className="paymentOn__itemOne" placeholder="XXXX XXXX XXXX XXXX"/>
                    <div className="paymentOn__container">
                        <div className="paymentOn__boxOne">
                            <label className="paymentOn__name">Data ważności</label>
                            <input className="paymentOn__itemTwo" placeholder="Nazwa karty"/>
                        </div>
                        <div className="paymentOn__boxTwo">
                            <label className="paymentOn__name">Kod zabezpieczający</label>
                            <input className="paymentOn__itemTwo" placeholder="Nazwa karty"/>
                        </div>
                    </div>
                </form>
            </Section>
        </div>
    )
}

export default Payment;