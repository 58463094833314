import { useState, useRef } from "react"
import components from "../../componentList";
import axios from "axios";
import { useAPI } from "../../context";

const JoinTheRenovation = () => {

    const { Link, useNavigate } = components;

    const { setContextLS, setNameLS, setSpecializationLS, setAuthorizationLS } = useAPI();
    
    const [numbers, setNumbers] = useState<any>(["","","","","",""])
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

    const [info, setInfo] = useState("");

    const navigate = useNavigate();

    const [fullName, setFullName] = useState("");
    const [specialization, setSpecialization] = useState("");

    const handleInputChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (value.length > 1) {
            return;
        }

        const newNumbers = [...numbers];
        newNumbers[index] = value;
        setNumbers(newNumbers);

        if (value.length === 1 && index < numbers.length - 1) {
            inputRefs.current[index + 1]?.focus();
        }
    };

    const areAllFieldsFilled = numbers.every((digit : any) => digit.length > 0);

    const handleNameChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const formattedValue = value
            .split(' ')
            .map((namePart : any) => namePart.charAt(0).toUpperCase() + namePart.slice(1))
            .join(' ');

        setFullName(formattedValue);
    }

    const handleSpecializationChange = (e : any) => {
        setSpecialization(e.target.value)
    }

    const join = () => {

        if (fullName !== "" && specialization !== "") {

            const joinedString = numbers.join('');

            const data = { key : joinedString, fullName : fullName, specialization : specialization}
            // @ts-ignore
            axios.post(`${global.REACT_APP_API_URL}/join/remont`, data)
              .then((response : any) => {
                setContextLS(joinedString)
                setSpecializationLS(specialization)
                setAuthorizationLS(response.data.member.uuid)
                setNameLS(fullName)
                navigate("/")
                setInfo("")
              })
              .catch((error : any) => {
                setInfo("Kod jest niepoprawny")
              });

        }
        else {
            setInfo("Pola nie zostały wypełnione")
        } 
    }

    return (
        <div className="joinTheRenovation">
            <h1 className="joinTheRenovation__title">{info === "" ? "Dołącz do remontu" : info}</h1>
            <div className="joinTheRenovation__wrapper">
                <input className="joinTheRenovation__value" value={fullName} placeholder="Imię i nazwisko" onChange={(e) => handleNameChange(e)}/>
                <input className="joinTheRenovation__value" value={specialization} placeholder="Specjalizacja" onChange={(e) => handleSpecializationChange(e)}/>
                <p className="joinTheRenovation__text">Poniżej wpisz kod aby dołączyć do remontu</p>
                <div className="joinTheRenovation__box">
                    {numbers.map((digit : string, index : number) => (
                        <input 
                            className="joinTheRenovation__item" 
                            value={digit} 
                            key={index} 
                            ref={(input) => (inputRefs.current[index] = input)} 
                            onChange={(event) => handleInputChange(index, event)}/>
                    ))}
                </div>
                <button onClick={join} className={areAllFieldsFilled ? "joinTheRenovation__button" : "joinTheRenovation__buttonTwo"} disabled={!areAllFieldsFilled}>Dołącz</button>
                <Link to="/" className="joinTheRenovation__link">Powrót</Link>
            </div>
        </div>
    )
}

export default JoinTheRenovation;
