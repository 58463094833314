import components from "../../componentList";

const Language = ({dispatch} : any) => {

    const { Section } = components;

    return (
        <Section dispatch={dispatch} title="Wybierz język">
            <p className="language__text">Wybierz język</p>
            <button className="language__button">Polski</button>
            <button className="language__button">English</button>
            <button className="language__button">Deutsch</button>
        </Section>

    )
}

export default Language;