import { useState } from "react";
import components from "../../componentList";

const ResetPassword = ({dispatch} : any) => {

  const { Link } = components;

  const [email, setEmail] = useState('');
  const [isVerification, setIsVerification] = useState(false);

  const handleInputChange = (event: any) => {
    let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmail(event.target.value);
    if (emailRegex.test(event.target.value)) {
      setIsVerification(true);
    } else {
      setIsVerification(false);
    }
  };

  return (
    <div className="resetPassword">
      <h1 className="resetPassword__title">Reset hasła</h1>
      <div className="resetPassword__wrapper">
        <p className="resetPassword__text">
          Wprowadz poniżej adres emai, aby zresetować hasło
        </p>
        <input
          className="resetPassword__value"
          type="text"
          placeholder="Adres email"
          value={email}
          onChange={(e) => handleInputChange(e)}
        />
        <button
          onClick={dispatch}
          className={
            isVerification
              ? "resetPassword__button"
              : "resetPassword__buttonTwo"
          }
          disabled={!isVerification}
        >
          Wyślij
        </button>
        <Link to="/" className="resetPassword__link">
          Powrót
        </Link>
      </div>
    </div>
  );
};

export default ResetPassword;
