import React,{ useState, useRef } from 'react';

function Authorization({dispatch} : any) {

    const [numbers, setNumbers] = useState<any>(["","","","","",""])
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

    const handleInputChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (value.length > 1) {
            return;
        }

        const newNumbers = [...numbers];
        newNumbers[index] = value;
        setNumbers(newNumbers);

        if (value.length === 1 && index < numbers.length - 1) {
            inputRefs.current[index + 1]?.focus();
        }
    };

    const areAllFieldsFilled = numbers.every((digit : any) => digit.length > 0);

    return (
        <div className="authorization">
            <h1 className="authorization__title">Kod Autoryzacji</h1>
            <div className="authorization__wrapper">
                <p className="authorization__text">Wpisz poniżej kod autoryzacji który przyszedł na Twój adres email</p>
                <div className="authorization__box">
                    {numbers.map((digit : string, index : number) => (
                        <input 
                            className="authorization__item" 
                            value={digit} 
                            key={index} 
                            ref={(input) => (inputRefs.current[index] = input)} 
                            onChange={(event) => handleInputChange(index, event)}/>
                    ))}
                </div>
                <button 
                    className={areAllFieldsFilled ? "authorization__button" : "authorization__buttonTwo"} 
                    disabled={!areAllFieldsFilled}
                    onClick={dispatch}
                    >
                    Zatwierdz
                </button>
            </div>
        </div>
    );
}

export default Authorization;