/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import { useAPI } from "../../context";
import axios from "axios";

const Messenger = ({ remont }: any) => {

  const { contextLS, authorizationLS, nameLS, newMessage, setNewMessage } = useAPI();

  const [messagesData, setMessagesData] = useState<any>([]);
  const [messageInput, setMessageInput] = useState<string>("");
  const [socket, setSocket] = useState<any>(null);
  const [userUuid, setUserUuid] = useState<any>("");
  const [isActive, setIsActive] = useState(false);
  const [nameUser, setNameUser] = useState(null);
  const [userWrite, setUserWrite] = useState(null);

  useEffect(() => {
    const socketLan = io("http://localhost:5000");
    setSocket(socketLan);
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("message", (updatedRemont: any) => {
        if (updatedRemont.senderUuid === authorizationLS) {
          setNewMessage(true);
          setUserWrite(updatedRemont.user);
        }
        setMessagesData((oldArray: any) => [...oldArray, updatedRemont]);
      });

      return () => {
        socket.disconnect();
      };
    }
  }, [socket]);

  const handleSendMessage = () => {
    if (messageInput && socket) {
      const messageData = {
        text: messageInput,
        senderUuid: userUuid.uuid,
        key: contextLS,
        user: nameLS,
        recipientUuid: authorizationLS,
      };

      socket.emit("message", messageData);
      setMessagesData((oldArray: any) => [...oldArray, messageData]);
      setMessageInput("");
    }
  };

  const checkUser = (uuid: any, fullName: any, messages: any) => {
    const instance = axios.create({
      withCredentials: true,
    });

    instance
      .get(
          // @ts-ignore
        `${global.REACT_APP_API_URL}/show/${contextLS}/members/${uuid.uuid}/messages`
      )

      .then((response) => {
        setUserUuid(uuid);
        setNameUser(fullName);
        setIsActive(true);
        setMessagesData(
          response.data.filter(
            (item: any) =>
              item.recipient === authorizationLS ||
              item.sender === authorizationLS
          )
        );

        if (userWrite === fullName) {
          setUserWrite(null)
        }
      })
      .catch((error) => {
        console.error("Błąd żądania GET:", error);
      });
  };

  const active = () => {
    setIsActive(false);
    setMessagesData([]);
  };

  const exit = () => {
    setNewMessage(false);
  };

  const reversedArray = [...messagesData].reverse();

  console.log(messagesData)

  return newMessage ? (
    <div className="messenger">
      <div className="messenger__options">
        {isActive ? (
          <button className="messenger__option" onClick={active}>
            <svg
              width="24"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
              fillRule="evenodd"
              clipRule="evenodd"
            >
              <path d="M20 .755l-14.374 11.245 14.374 11.219-.619.781-15.381-12 15.391-12 .609.755z" />
            </svg>
          </button>
        ) : null}
        {isActive ? <p>{nameUser}</p> : null}
        <button className="messenger__option" onClick={exit}>
          <svg
            id="arrow"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 18.764 15.317"
          >
            <g id="Group_108" data-name="Group 108">
              <path
                id="Path_603"
                data-name="Path 603"
                d="M7.659,55.284a.312.312,0,0,1-.024-.162c.008-.044-.02-.08-.084-.108s-.1-.05-.1-.066-.046-.028-.138-.036L7.18,54.9H3.446a1.843,1.843,0,0,1-1.352-.563,1.844,1.844,0,0,1-.562-1.352V44.561a1.843,1.843,0,0,1,.562-1.352,1.844,1.844,0,0,1,1.352-.563H7.276a.361.361,0,0,0,.245-.078.4.4,0,0,0,.12-.233,2.014,2.014,0,0,0,.036-.281,2.977,2.977,0,0,0-.006-.317q-.012-.191-.012-.239a.388.388,0,0,0-.383-.383H3.446a3.32,3.32,0,0,0-2.435,1.011A3.32,3.32,0,0,0,0,44.561v8.425a3.319,3.319,0,0,0,1.011,2.435,3.319,3.319,0,0,0,2.435,1.012h3.83a.363.363,0,0,0,.245-.078.4.4,0,0,0,.12-.234,2.017,2.017,0,0,0,.036-.281,2.974,2.974,0,0,0-.006-.317Q7.659,55.331,7.659,55.284Z"
                transform="translate(0 -41.115)"
              />
              <path
                id="Path_604"
                data-name="Path 604"
                d="M123.574,56.983l-6.51-6.51a.766.766,0,0,0-1.3.539v3.446H110.4a.776.776,0,0,0-.766.766v4.6a.777.777,0,0,0,.766.766h5.361v3.446a.762.762,0,0,0,1.3.538l6.51-6.51a.752.752,0,0,0,0-1.077Z"
                transform="translate(-105.038 -49.863)"
              />
            </g>
          </svg>
        </button>
      </div>
      <div className="messenger__wrap">
        {isActive ? (
          <input
            className="messenger__input"
            type="text"
            placeholder="Treść wiadomości"
            value={messageInput}
            onChange={(e) => setMessageInput(e.target.value)}
          />
        ) : null}

        {remont.members === undefined
          ? null
          : remont.members.map((item: any, index: any) =>
              nameLS !== item.fullName && !isActive ? (
                <button
                  className={
                    userWrite === item.fullName
                      ? "messenger__userNot"
                      : "messenger__user"
                  }
                  key={index}
                  onClick={() => checkUser(item, item.fullName, item.messages)}
                >
                  {item.fullName}
                </button>
              ) : null
            )}
        {isActive ? (
          <button className="messenger__send" onClick={handleSendMessage}>
            Wyślij wiadomości
          </button>
        ) : null}
        <div className="messenger__main">
          {isActive
            ? reversedArray.map((message: any, index: number) => (
                <div key={index} className="messenger__box">
                  <div
                    className={
                      message.user === nameLS
                        ? "messenger__meBox"
                        : "messenger__friendBox"
                    }
                  >
                    <p className="messenger__cont">
                      {message.user === nameLS ? "Ty" : message.user}
                    </p>
                    <p
                      className={
                        message.user === nameLS
                          ? "messenger__me"
                          : "messenger__friend"
                      }
                    >
                      {message.text}
                    </p>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  ) : null;
};

export default Messenger;
