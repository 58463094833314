export function getError(status : any) {
    let msg = '';
  
    switch (true) {
      case status === 400:
        msg = 'errors.api.badRequest';
        break;
      case status === 500:
        msg = 'errors.api.serverError';
        break;
      default:
        break;
    }
  
    return msg;
}