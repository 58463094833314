import images from "../../imagesList";
import components from "../../componentList";
import { useAPI } from "../../context";
import { useState } from "react";

import { updateProfile } from "../../api/editProfile/editProfileApi";

const EditProfile = ({ dispatch }: any) => {
  const { avatar, write } = images;
  const { Tab } = components;

  const { dataUser, setDataUser } = useAPI();

  const [editData, setEditData] = useState({
    name: dataUser.name,
    id: dataUser.id,
    password: dataUser.password,
  });
  const { name, id, password } = editData;
  const [error, setIsError] = useState(false);
  const [succesful, setSuccesful] = useState(false);

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const value = evt.target.value;
    setEditData({ ...editData, [evt.target.name]: value });
  };
  
  const editProfile = () => {
    const data = { name: name, id: id, password: password };

    updateProfile(data)
      .then(() => {

        const updatedObject = { ...data };
        updatedObject.name = name;

        setDataUser(updatedObject);
        setSuccesful(true);

      })
      .catch((error) => {
        setIsError(true);
      });
  };

  return (
    <div className="editProfile">
      <Tab dispatch={dispatch} title="Edytuj profil" />
      <div className="editProfile__figure">
        <img className="editProfile__image" src={avatar} alt="avatar" />
        <button className="editProfile__buttonEdit">
          <img className="editProfile__icon" src={write} alt="avatar" />
        </button>
      </div>
      {!error ? (
        <div className="editProfile__container">
          <form
            className="editProfile__form"
            onSubmit={(event) => event.preventDefault()}
          >
            <input
              onChange={(e) => handleChange(e)}
              className="editProfile__item"
              placeholder="Imię i nazwisko"
              value={name}
              name="name"
            />
            <input
              onChange={(e) => handleChange(e)}
              className="editProfile__item editProfile__item-color"
              placeholder="Email"
              value={dataUser.email}
              disabled={true}
            />
            <input
              onChange={(e) => handleChange(e)}
              className="editProfile__item"
              placeholder="Nowe hasło"
              value={password}
              name="password"
              type="password"
            />
          </form>
          {succesful ? (
            <p className="editProfile__info">Zmiany zostały zapisane</p>
          ) : null}
          <p className="editProfile__text">Dołączył/a 19.05.2023</p>
          <button className="editProfile__buttonSave" onClick={editProfile}>
            Zatwiedz
          </button>
        </div>
      ) : (
        <div className="repairs__error" style={{ marginTop: "50px" }}>
          <p>Coś poszło nie tak spróbuj ponownie poniżej</p>
        </div>
      )}
    </div>
  );
};

export default EditProfile;
