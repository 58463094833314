import React,{ useState } from "react";
import components from "../../componentList";

const Subscription = ({dispatch} : any) => {

    const { Payment, Section } = components;

    const [isPayment, setIsPayment] = useState(false);

    const payment = () => {
        setIsPayment(!isPayment)
    }

    return (
        <Section dispatch={dispatch} title="Abonament">
            <button className="subscription__button">Członek remontu 0$/mies</button>
            <p className="subscription__text">Pakiet darmowy umożliwia dołączenie do remontu</p>
            <button className="subscription__button">Prowadzący remont 5$/mies</button>
            <p className="subscription__text">Pakiet premium umożliwia tworzenie oraz dołączenie do remontu</p>
            <button className="subscription__button subscription__button--margin" onClick={payment}>Metoda płatności</button>
            <Payment isPayment={isPayment} dispatch={payment}/>
        </Section>
    )
}

export default Subscription