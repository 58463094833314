import { useAPI } from "../../context";
import { useState } from "react";
import axios from "axios";
import useKey from "../../hooks/useKey";
import components from "../../componentList";
import useProfile from "../../hooks/useProfile";

const Creator = () => {

  const { Spinner } = components;
  const { loadingKey, key, setLoadingKey, setKey } = useKey();

  const [remont, setRemont] = useState<any>({ name: "", description: "" });
  const [isSaveRemont, setIsSaveRemont] = useState(false);
  const [loading, setLoading] = useState(false)
  const [error, setIsError] = useState(false);

  const { name, description } = remont;

  const { setList, dataUser, cookies } = useAPI();
  useProfile();

  const create = () => {

    setLoading(true)

    let myKey = key.join("");

    const data = { name: name, description: description, key: myKey, id : dataUser.id, admin : dataUser.name, completed : false };

    axios
        // @ts-ignore
      .post(`${global.REACT_APP_API_URL}/create/remont`, data)
      .then((response: any) => {
        setList((oldArray: any) => [
          ...oldArray,
          { name: name, description: description, key: myKey },
        ]);
        setRemont({
          ...remont,
          name: (remont.name = ""),
          description: (remont.description = ""),
        });
        setIsSaveRemont(true)
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        setIsError(true);
      });
  };

  const handleChange = (evt: any) => {
    const value = evt.target.value;
    setRemont({ ...remont, [evt.target.name]: value });
  };

  const verification = name.length === 0 || description.length === 0;

  const newRemont = async() => {
    
    try {
      const instance = axios.create({
        withCredentials: true,
      });
      // @ts-ignore
      const response = await instance.get(`${global.REACT_APP_API_URL}/random/key`);
      setKey(response.data.key.split(''));
      setIsSaveRemont(false);
      setLoadingKey(true);
      
    } catch (error) {
      setIsSaveRemont(false);
      setLoadingKey(false);
      setIsError(true);
    }
  }

  return (
    <div className="creator">
      {!error ? <div className={loading ? "creator__wrapperTwo" : "creator__wrapper"}>
      {cookies.token ? 
       !isSaveRemont ?
       <div className="creator__container">
        <h2 className="creator__title">Kreator remontu</h2>
        <p className="creator__text">Wpisz poniżej nazwe remontu oraz opis</p>
        <form
          className="creator__form"
          onSubmit={(event) => event.preventDefault()}
        >
          <input
            maxLength={25}
            className="creator__item"
            value={name}
            name="name"
            onChange={(e) => handleChange(e)}
            placeholder="Nazwa"
          />
          <textarea
            maxLength={500}
            className="creator__item creator__item--area"
            value={description}
            name="description"
            placeholder="Opis remontu"
            onChange={(e) => handleChange(e)}
          />
          <div className="creator__box">
            {loadingKey
              ? key.map((digit: any, index: number) => (
                  <div className="creator__itemTwo" key={index}>
                    {digit}
                  </div>
                ))
              : null}
          </div>
          {loadingKey ? (
            <button
              className={
                !verification
                  ? "creator__item creator__item--submit"
                  : "creator__item creator__item--submitTwo"
              }
              disabled={verification && loadingKey ? true : false}
              onClick={create}
            >
              Stwórz remont
            </button>
          ) : (
            <div className="creator__file">
              <Spinner />
            </div>
          )}
        </form>
        </div>
        : 
        <>
          <p className="creator__text">Remont został stworzony</p>
          <button className="creator__item creator__item--submitThree" onClick={newRemont}>Nowy Remont</button>
        </> : <p className="creator__text">Aby móc tworzyć remonty musisz posiadać dostęp</p>}
      </div> : <div className="creator__wrapper">
            <p className="creator__text">Coś poszło nie tak spróbuj póżniej</p>
      </div>}
    </div>
  );
};

export default Creator;
