import { BrowserRouter, Routes, Route } from "react-router-dom";
import components from "../../componentList";

const App = () => {

  const { Home, Register, Navigation, Login, Creator, Repairs, JoinTheRenovation, PasswordReminder } = components;

  return (
    <BrowserRouter>
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/register" element={<Register />}/>
        <Route path="/login" element={<Login />}/>
        <Route path="/creator" element={<Creator />}/>
        <Route path="/repairs" element={<Repairs />}/>
        <Route path="/joinTheRenovation" element={<JoinTheRenovation />}/>
        <Route path="/passwordReminder" element={<PasswordReminder />}/>
      </Routes>
    </BrowserRouter>
  );
};

export default App;