import components from "../../componentList";
import axios from "axios";
import { useState } from "react";

const Register = () => {
  
  const { Link, Loader } = components;

  const [user, setUser] = useState({ name: "", email: "", password: "" });
  const [info, setInfo] = useState("");
  const [loading, setLoading] = useState(false);
  const [succesful, setSuccesful] = useState(false);

  const { name, email, password } = user;

  const register = () => {
    setLoading(true);

    if (name !== "" && email !== "" && password !== "") {

      const data = { name: name, email: email, password: password };

      axios
          // @ts-ignore
        .post(`${global.REACT_APP_API_URL}/user/register`, data)
        .then((response: any) => {
          setInfo("Rejestracja przebiegła pomyślnie");
          setLoading(false);
          setSuccesful(true);
          setUser({ ...user, name : user.name = "", password : user.password = "" , email : user.email = ""});
        })
        .catch((error: any) => {
          setInfo("Nie udało się zarejestrować");
          setLoading(false);
        });
    } else {
      setInfo("Nie udało się zarejestrować");
      setLoading(false);
      setUser({ ...user, password : user.password = "" });
    }
  };

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const value = evt.target.value;
    setUser({ ...user, [evt.target.name]: value });
  };

  return (
    <div className="register">
      {succesful ? (
        <h1 className="register__title">
          Rejestracja przebiegła pomyślenie
          <Link className="register__logged" to="/login">
            {" "}
            zaloguj się
          </Link>
        </h1>
      ) : (
        <h1 className="register__title">
          {info === "" ? "Rejestracja" : info}
        </h1>
      )}
      <div className="register__wrapper">
        {loading ? <Loader /> : null}
        {!loading ? (
          <form
            className="register__form"
            onSubmit={(event) => event.preventDefault()}
          >
            <input
              onChange={(e) => handleChange(e)}
              className="register__item"
              placeholder="Imię i nazwisko"
              type="text"
              value={name}
              name="name"
            />
            <input
              onChange={(e) => handleChange(e)}
              className="register__item"
              placeholder="Email"
              type="text"
              value={email}
              name="email"
            />
            <input
              onChange={(e) => handleChange(e)}
              className="register__item"
              placeholder="Hasło"
              type="password"
              value={password}
              name="password"
            />
            <button
              onClick={register}
              className="register__item register__item--submit"
            >
              Zarejestruj się
            </button>
          </form>
        ) : null}
        {!loading ? (
          <p className="register__text">Posiadasz już konto ?</p>
        ) : null}
        {!loading ? (
          <Link className="register__link" to="/login">
            Zaloguj się
          </Link>
        ) : null}
      </div>
    </div>
  );
};

export default Register;
