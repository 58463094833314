import Modal from "react-modal";
import { customStyles } from "../../utils/customStyles";

const ModalCalendar = ({ isModalCalendar, closeModal, calendarData }: any) => {

  Modal.setAppElement("#root");

  return (
    <Modal isOpen={isModalCalendar} style={customStyles} onRequestClose={closeModal}>
      <div className="modalCalendar">
        {calendarData.map((item : any, index : any) => (
         !item.private ? <div className="modalCalendar__wrapper" key={index}>
            <div className="modalCalendar__box">
                <p className="modalCalendar__description">{item.description}</p>
                <p className="modalCalendar__description">{item.currentDate}</p>
            </div>
          </div> : null
        ))}
      </div>
    </Modal>
  );
};

export default ModalCalendar;