import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/app';
import { APIContextProvider } from "./context"
import "./styles/theme/theme.scss";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <APIContextProvider>
      <App />
    </APIContextProvider>
);

