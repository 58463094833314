import components from "../../componentList";
import images from "../../imagesList";
import { useReducer } from "react";
import { useAPI } from "../../context";
import useProfile from "../../hooks/useProfile";

const Profile = () => {

  const { avatar } = images;
  const { EditProfile, Language, Information, Subscription, Link, Spinner } = components;

  const { removeCookie, dataUser, loadingProfile, setLoadingProfile } = useAPI();
  const { error } = useProfile()

  const initialState = { component: null, isOption: false };
  
  function reducer(state: any, action: any) {

    const start = () => action.dispatch({ type: "Wyłącz opcje" })

    switch (action.type) {
      case "Edytuj profil":
        return {...state, component: <EditProfile dispatch={start}/>, isOption: true};
      case "Język aplikacji":
        return {...state, component: <Language dispatch={start}/>, isOption: true};
      case "Abonament":
        return {...state, component: <Subscription dispatch={start}/>, isOption: true};
      case "Regulamin":
        return {...state, component: <Information dispatch={start} title="Regulamin"/>, isOption: true};
      case "Polityka prywatności":
        return {...state, component: <Information dispatch={start} title="Polityka prywatności"/>, isOption: true};
      case "Wyloguj się":
        return {...state, isOption: false};
      case "Wyłącz opcje":
        return { ...state, isOption: false };
      default:
        throw new Error();
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const arrayOptions = ["Edytuj profil", "Język aplikacji", "Abonament", "Regulamin", "Polityka prywatności", "Wyloguj się"];

  const loggOut = (item: string) => {
    dispatch({ type: item, dispatch })
    if (item === "Wyloguj się") {
      removeCookie("token")
      setLoadingProfile(false)
    }
  }

  return (
    <div className="profile"> 
      {loadingProfile ? 
      <>
      <div className={state.isOption ? "profile__optionOn" : "profile__optionOff"}>
        {state.component}
      </div>
      <h1 className="profile__title">Mój profil</h1>
      <div className="profile__figure">
        <img className="profile__image" src={avatar} alt="avatar" />
      </div>
      <p className="profile__name">{dataUser.name}</p>
      <div className="profile__wrapper">
        <Link className="profile__link" to="/creator">Kreator remontów</Link>
        <Link className="profile__link" to="/repairs">Lista remontów</Link>
        {arrayOptions.map((item, index) => (
          <button className="profile__button" onClick={() => loggOut(item)} key={index}>
            {item}
          </button>
        ))}
      </div>
      </> : error ? <div className="repairs__error">
        <p>Coś poszło nie tak spróbuj ponownie póżniej</p>
      </div> : <Spinner />}
    </div>
  );
};

export default Profile;

