import components from "../../componentList";

function NewPassword() {

    const { Link } = components;

    return (
        <div className="newPassword">
            <h1 className="newPassword__title">Nowe hasło</h1>
            <div className="newPassword__wrapper">
                <form className="newPassword__form" onSubmit={(event) => event.preventDefault()}>
                    <input className="newPassword__item" placeholder="Nowe hasło" type="password"/>
                    <input className="newPassword__item" placeholder="Powtórz hasło" type="password"/>
                    <Link to="/login" className="newPassword__item newPassword__item--submit">Zatwierdz</Link>
                </form>
            </div>
        </div>
    );
}

export default NewPassword;