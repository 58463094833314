import { useReducer} from 'react';
import components from "../../componentList";

const PasswordReminder = () => {

    const { ResetPassword, Authorization, NewPassword } = components;

    const initialState = { component: <ResetPassword dispatch={() => dispatch({ type: "code" })}/> };

    function reducer(state : any, action : any) {

        switch (action.type) {
            case "code":
                return {...state, component: <Authorization dispatch={() => dispatch({ type: "new password" })}/>};
            case "new password":
                return {...state, component: <NewPassword />};
            default:
                throw new Error();
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  return (state.component);
}

export default PasswordReminder;