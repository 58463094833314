import React,{ useState, useContext } from "react";
import { APIContext } from "./@types.context";
import { useCookies } from 'react-cookie';
import useLocalstorage from "../hooks/useLocalStorage";

export const APIContextProvider = ({ children } : any) => {
 
  const [isHamburgerMenu, setIsHamburgerMenu] = useState(false);  
  const [list, setList] = useState([{name: '', description: '', key: '', ids: '', completed : false}]);
  const [remont, setRemont] = useState({name : "", description : "", id : ""})
  const [cookies, setCookie, removeCookie] = useCookies(['token']);

  const [dataUser, setDataUser] = useState({name : "", email : "", password : "", id : ""});
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [dataRemont, setDataRemont] = useState([]);
  const [isShow, setIsShow] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null);
  const [base64Data, setBase64Data] = useState<string | null>(null);
  const [arrayDescription, setArrayDescription] = useState([]);

  const [noticeBoard, setNoticeBoard] = useState([]);  
  const [specification, setSpecification] = useState<any>([]);
  const [schedule, setSchedule] = useState<any>([]);
  const [characteristic, setCharacteristic] = useState<any>([]);
  const [newMessage, setNewMessage] = useState(false);

  const { setContextLS, contextLS, nameLS, setNameLS, specializationLS, setSpecializationLS, authorizationLS, setAuthorizationLS } = useLocalstorage();

  return (
    <APIContext.Provider
      value={{
        isHamburgerMenu, 
        setIsHamburgerMenu,
        list, 
        setList,
        remont, 
        setRemont,
        cookies, 
        setCookie, 
        removeCookie,
        dataUser, 
        setDataUser,
        loadingProfile, 
        setLoadingProfile,
        setContextLS, 
        contextLS,
        nameLS, 
        setNameLS,
        specializationLS, 
        setSpecializationLS,
        dataRemont, 
        setDataRemont,
        isShow,
        setIsShow,
        selectedFile, 
        setSelectedFile,
        base64Data, 
        setBase64Data,
        arrayDescription, 
        setArrayDescription,
        authorizationLS, 
        setAuthorizationLS,
        specification, 
        setSpecification,
        schedule, 
        setSchedule,
        noticeBoard, 
        setNoticeBoard,
        characteristic, 
        setCharacteristic,
        newMessage, 
        setNewMessage
      }}
    >
      {children}
    </APIContext.Provider>
  );
};

export function useAPI() {
  const context = useContext(APIContext);
  return context;
}
