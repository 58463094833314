/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useAPI } from "../../context";
import images from "../../imagesList";
import components from "../../componentList";
import useRemontList from "../../hooks/useRemontList";
import Calendar from 'react-calendar';
import moment from 'moment';

const User = () => {

    const { Spinner, Loader, PhotoUpload, ModalImage, ModalCalendar, Messenger } = components;
    const { chat } = images;

    const { contextLS, nameLS, specializationLS, setNameLS, selectedFile, base64Data, setSelectedFile, setBase64Data, setAuthorizationLS,
      noticeBoard, setNoticeBoard, specification, setSpecification, schedule, setSchedule, characteristic, setCharacteristic, authorizationLS,
      setContextLS, setSpecializationLS, setNewMessage } = useAPI();

    const [loadingTwo, setLoadingTwo] = useState(false);
    const [loadingThree, setLoadingThree] = useState(false);
    const [loadingFour, setLoadingFour] = useState(false);
    const [value, setValue] = useState("");
    const [myRemonts, setMyRemonts] = useState<any>([]);

    const [loadNoticeBoard, setLoadNoticeBoard] = useState(false);
    const [loadSpecification, setLoadSpecification] = useState(false);
    const [loadSchedule, setLoadSchedule] = useState(false);
    const [loadAuthorization, setLoadAuthorization] = useState(false);
    const [loadCharacteristic, setLoadCharacteristic] = useState(false);
    const [user, setUser] = useState([]);

    const [index, setIndex] = useState<any>(null);
    const [isModal, setIsModal] = useState(false);
    const [document, setDocument] = useState("");

    const { remont, loading, error, setIsError } = useRemontList();  

    const [isModalCalendar, setIsModalCalendar] = useState<any>(false);
    const [calendarData, setCalendarData] = useState<any>([]);

      useEffect(() => {
        if (contextLS) {
          const fetchData = async () => {
            try {
              const instance = axios.create({
                withCredentials: true,
              });
                // @ts-ignore
              const response = await instance.get(`${global.REACT_APP_API_URL}/list/remont`);
              
              setMyRemonts(response.data.list.filter((item : any) => item.key === contextLS));
              setLoadingTwo(true)

            } catch (error) {
              setIsError(true)
              console.error('Błąd podczas pobierania danych użytkownika 1:', error);
            }
          }
        
        fetchData();
        }
      }, [contextLS]);

      useEffect(() => {
        if (loadingTwo) {
          const fetchData = async () => {
            try {
              const instance = axios.create({
                withCredentials: true,
              });
                // @ts-ignore
              const responses = await Promise.all(myRemonts[0].noticeBoard.map((url : any) => instance.get(`${global.REACT_APP_API_URL}/post/noticeBoard/${url.uuid}`)));
              const newNoticeBoardData = responses.map((response) => ({...response.data, admin : remont.admin, date : date(remont.currentDate)}));

              setNoticeBoard(newNoticeBoardData)
              setLoadNoticeBoard(true)

            } catch (error) {
              console.error('Błąd podczas pobierania danych użytkownika 2:', error);
            }
          }
        
        fetchData();
        }
      }, [loadingTwo]);


      useEffect(() => {
        if (loadingTwo) {
          const fetchData = async () => {
            try {
              const instance = axios.create({
                withCredentials: true,
              });
                // @ts-ignore
              const responses = await Promise.all(myRemonts[0].specification.map((url : any) => instance.get(`${global.REACT_APP_API_URL}/post/specification/${url.uuid}`)));
              const newNoticeBoardData = responses.map((response) => ({...response.data, admin : remont.admin, date : date(remont.currentDate)}));

              setSpecification(newNoticeBoardData)
              setLoadSpecification(true)

            } catch (error) {
              console.error('Błąd podczas pobierania danych użytkownika:', error);
            }
          }
        
        fetchData();
        }
      }, [loadingTwo]);


      useEffect(() => {
        if (loadingTwo) {
          const fetchData = async () => {
            try {
              const instance = axios.create({
                withCredentials: true,
              });
                // @ts-ignore
              const responses = await Promise.all(myRemonts[0].schedule.map((url : any) => instance.get(`${global.REACT_APP_API_URL}/post/schedule/${url.uuid}`)));
              const newNoticeBoardData = responses.map((response) => ({...response.data, admin : remont.admin, date : date(remont.currentDate)}));

              setSchedule(newNoticeBoardData)
              setLoadSchedule(true)
            

            } catch (error) {
              console.error('Błąd podczas pobierania danych użytkownika: ', error);
            }
          }
        
        fetchData();
        }
      }, [loadingTwo]);

      useEffect(() => {
        if (loadingTwo) {
          const fetchData = async () => {
            try {
              const instance = axios.create({
                withCredentials: true,
              });
                // @ts-ignore
              const response = await instance.get(`${global.REACT_APP_API_URL}/authorization/user/${contextLS}/${authorizationLS}`);
              setLoadAuthorization(true)
              setUser(response.data)
             
            } catch (error) {
              setLoadCharacteristic(true)
            }
          }
    
        fetchData();
        }
      }, [loadingTwo]);

      useEffect(() => {
        if (loadAuthorization) {
          const fetchData = async () => {
            try {
              const instance = axios.create({
                withCredentials: true,
              });
                // @ts-ignore
              const responses = await Promise.all(user.map((url : any) => instance.get(`${global.REACT_APP_API_URL}/post/characteristic/${url.uuid}`)));
              const newNoticeBoardData = responses.map((response) => ({...response.data, admin : remont.admin, date : date(remont.currentDate)}));

              setCharacteristic(newNoticeBoardData)
              setLoadCharacteristic(true)

            } catch (error) {
              setLoadCharacteristic(true)
              console.error('Błąd podczas pobierania danych użytkownika 44: ', error);
            }
          }
        
        fetchData();
        }
    }, [loadAuthorization]);

    const handleChange = (e : any) => {
        setValue(e.target.value);
    }

    const addDescription = () => {

      const currentDate = new Date();
      setLoadingThree(true)
  
      const nameImg = selectedFile === null ? null : selectedFile.name  
  
      const data = { description : value, key : contextLS, base64Data : base64Data, nameImg : nameImg, nameUser : nameLS, specialization : specializationLS, authorization : authorizationLS } 
  
      axios
          // @ts-ignore
        .post(`${global.REACT_APP_API_URL}/post/characteristic`, data)
        .then((response: any) => {
          setCharacteristic((oldArray: any) => [
            ...oldArray,
            { 
              ...data, 
              currentDate : currentDate,
              uuid : response.data.uuid,
              base64Data : response.data.base64Data
            }
          ]);
          setSelectedFile(null);
          setIndex(null);
          setLoadingThree(false);
          setValue("");
          setBase64Data(null);
        })
        .catch((error: any) => {
          setIsError(true);
          setSelectedFile(null);
          setIndex(null);
          setLoadingThree(false);
          setValue("");
          setBase64Data(null);
        });
    }

    const date = (dateString : any) => {

      const dateObject = new Date(dateString);
      
      const year = dateObject.getFullYear();
      const month = String(dateObject.getMonth() + 1).padStart(2, '0');
      const day = String(dateObject.getDate()).padStart(2, '0');

      const formattedDate = `${year}-${month}-${day}`;

      return formattedDate
    }

    const removeRemont = (uuidToRemove: any) => {
      
      setLoadingFour(true);
    
      axios
          // @ts-ignore
        .delete(`${global.REACT_APP_API_URL}/in/remont/${uuidToRemove}`)
        .then((response: any) => {
          setLoadingFour(false);
          setCharacteristic((prevCharacteristic : any) =>
            prevCharacteristic.filter((item : any) => item.uuid !== uuidToRemove)
          );
        })
        .catch((error: any) => {
          setLoadingFour(false);
          setIsError(true);
        });
    };

    const exit = () => {
      setNameLS("")
      setAuthorizationLS("")
      setContextLS("")
      setSpecializationLS("")
    }

    const showDocument = (item : any) => {
      setIsModal(true)
      setDocument(item)
    }

    const closeModal = () => {
      setIsModal(false)
      setIsModalCalendar(false)
    }
    
    const customTileClassName = ({ date }: any) => {
      if (loadSchedule) {
        const dateString = moment(date).format('YYYY-MM-DD');
        const foundDay = schedule.find((item: any) => moment(item.currentDate).format('YYYY-MM-DD') === dateString);
    
        if (foundDay) {
          if (foundDay.private === true) {
            return null;
          }
          return 'highlight-day';
        }
      }
      return null;
    };

    useEffect(() => {

      const allPrivatesAreTrue = calendarData.every((item: any) => item.private === true);
      const allPrivatesAreFalse = !allPrivatesAreTrue;

      if (calendarData.length !== 0 && allPrivatesAreFalse) {
        setIsModalCalendar(true);
      }
    }, [calendarData]);

    const onChange = (e : any) => {

      const dateString = moment(e).format('YYYY-MM-DD');
      setCalendarData(schedule.filter((item : any) => item.currentDate === dateString))

    }

    const reversedArray = (props : any) => {
      return [...props].reverse()
    };

    return (
        <div className="user">
          <ModalImage {...{isModal, closeModal, document}}/>
          <ModalCalendar {...{isModalCalendar, closeModal, calendarData}}/>
          <Messenger {...{remont}}/>
          {!error ?
            loading && loadingTwo ?
            myRemonts.length !== 0 ?
            <>
            <div className="user__information">
              <h2 className="user__text">{remont.name}</h2>
              <h3 className="user__text">{remont.description}</h3>
              <div className="user__wrap">
                <p className="user__title">Prowadzący: {remont.admin}</p>
                <p className="user__text">{date(remont.currentDate)}</p>
              </div>
            </div>
            <button onClick={exit} className="user__buttonExit">Opuść remont</button>
            
            <div className="user__wrapper">
                <h2 className="user__name">Twoje remonty</h2>
                {loadingFour ? <Loader /> : null}
                {loadCharacteristic ? reversedArray(characteristic).map((item : any, index : any) => (
                  item.name !== nameLS ? (
                  <div className="renovation__container" key={index}>
                        <button onClick={() => removeRemont(item.uuid)} className="renovation__buttonRemove">Usuń remont</button>
                        <p className="renovation__name">{date(item.currentDate)}</p>
                        <p className="renovation__name">{item.description}</p>
                        {item.base64Data === null ? null : 
                        <button className="renovation__buttonImage" onClick={() => showDocument(item)}>
                          <img className="renovation__image" src={item.base64Data} alt={item.name} />
                        </button>}  
                    </div>) : null
                )) : <Loader /> }
            </div>
            <div className="user__wrapperTwo">
             {loadingThree ? <Loader /> : null}
                <textarea className="user__value" placeholder="Co dzisiaj zrobiłeś ?" value={value} onChange={(e) => handleChange(e)}/>
                <div className="user__buttonWrapper">
                  <button className={value.length !== 0 ? "user__buttonActive" : "user__button"} onClick={addDescription} disabled={value.length === 0 ? true : false}>Wyslij</button>
                </div>
                <PhotoUpload index={0} myIndex={index} setIndex={setIndex}/>
            </div>
            <div className="user__wrapper">
                <h2 className="user__name">Tablica ogłoszeń</h2>
                {loadNoticeBoard ? reversedArray(noticeBoard).map((item: any, index: any) => (
                  !item.private ? (
                  <div className="renovation__container" key={index}>
                        <p className="renovation__name">{item.date}</p>
                        <p className="renovation__name">{item.description}</p>
                        {item.base64Data === null ? null : 
                        <button className="renovation__buttonImage" onClick={() => showDocument(item)}>
                          <img className="renovation__image" src={item.base64Data} alt={item.name} />
                        </button>}                  
                  </div>
                  ) : null
                )) : <Loader />}
            </div>
            <div className="user__wrapper">
                <h2 className="user__nameTwo">Harmonogram</h2>
                <Calendar onChange={onChange} tileClassName={customTileClassName} />
            </div>
            <div className="user__wrapper">
                <h2 className="user__name">Specyfikacja</h2>
                {loadSpecification ? reversedArray(specification).map((item: any, index: any) => (
                  !item.private ? (
                  <div className="renovation__container" key={index}>
                        <p className="renovation__name">{item.date}</p>
                        <p className="renovation__name">{item.description}</p>
                        {item.base64Data === null ? null : 
                        <button className="renovation__buttonImage" onClick={() => showDocument(item)}>
                          <img className="renovation__image" src={item.base64Data} alt={item.name} />
                        </button>}                  
                  </div>
                  ) : null
                )) : <Loader />}
            </div>
            <div className="user__block"/>
            <footer className="user__footer">
                <div className="user__box">
                    <p className="renovation__text">Napisz do</p>
                    <button className="user__write" onClick={() => setNewMessage(true)}>
                      <img className="user__icon" src={chat} alt="chat" />
                    </button>
                    
                </div>
                <div className="user__box">
                    <p className="renovation__text">Członkowie</p>
                    <p className="renovation__text">{remont.members.length}</p>
                </div>
            </footer>
            </>
            : <p className="user__textTwo">Remont prawdopodobnie został ukończony lub usunięty
            <span className="user__link" onClick={exit}> wróć do strony głównej</span>
              </p> : <Spinner /> : <div className="repairs__error">
                <p>Coś poszło nie tak spróbuj póżniej</p>
              </div> }
        </div>
    )
}

export default User;
