/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import axios from "axios";
import { useAPI } from "../context";

const useProfile = () => {

  const { cookies, setDataUser, dataUser, setLoadingProfile } = useAPI();
  const [error, setIsError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (cookies.token) {
        try {
          const instance = axios.create({
            withCredentials: true,
          });
          // @ts-ignore
          const response = await instance.get(`${global.REACT_APP_API_URL}/user/user`, {
            headers: {
              Authorization: `Bearer ${cookies.token}`,
            },
          });
          setDataUser({
            ...dataUser,
            name: dataUser.name = response.data.name,
            email: dataUser.email = response.data.email,
            password: dataUser.password = "",
            id: dataUser.id = response.data.id,
          })
          setLoadingProfile(true);
        } catch (error) {
          setIsError(true)
          console.error('Błąd podczas pobierania danych użytkownika:', error);
        }
      }
    };

    fetchData();
  }, [cookies.token]);

  return { error }
};

export default useProfile;
