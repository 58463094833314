import images from "../../imagesList";
import components from "../../componentList";
import { useAPI } from "../../context";

const Navigation = () => {

  const { Link, useLocation } = components;
  const { home, logo, user } = images;
  const { loadingProfile, cookies, isShow, setIsShow, setNameLS, setAuthorizationLS, setContextLS, setSpecializationLS, nameLS } = useAPI();

  const location = useLocation()

  const logged = () => {
    setNameLS("")
    setAuthorizationLS("")
    setContextLS("")
    setSpecializationLS("")
    setIsShow(false)
  }

  return (
    <header className="navigation">
      <img className="navigation__logo" src={logo} alt="logo" />
      {nameLS !== "" ? <p className="navigation__name">{nameLS}</p> : null}
      <ul className="navigation__list">
        {isShow ? <button className="renovation__buttonBack" onClick={() => setIsShow(false)} style={{cursor : "pointer"}}>
            &lt;
        </button> : null}
        {!loadingProfile && !cookies.token ? (
          <>
          <li className="navigation__group">
            <span className="navigation__element">O nas</span>
            <ul className="navigation__items">
              <li className="navigation__item">Remont na stoczni</li>
              <li className="navigation__item">Remont na doku</li>
              <li className="navigation__item">Przegląd jednostki</li>
              <li className="navigation__item">Remont na stoczni</li>
              <li className="navigation__item">Prace na statku</li>
              <li className="navigation__item">Slipowanie</li>
            </ul>
          </li>
          </>
        ) : (
          <>
            <li className="navigation__item navigation__item--cursor">
              <Link to="/creator" onClick={() => setIsShow(false)}>
                Kreator remontu
              </Link>
            </li>
            <li className="navigation__item navigation__item--cursor">
              <Link to="/repairs" onClick={() => setIsShow(false)}>
                Lista remontów
              </Link>
            </li>
            <li className="navigation__item navigation__item--profile">
              <Link to="/" onClick={() => setIsShow(false)}>
                <img className="navigation__icon navigation__icon--profile" src={user} alt="user" />
              </Link>
            </li>
          </>
        )}
        <li>
          {location.pathname === "/" && !cookies.token ?<Link className="navigation__item navigation__item--cursor" to="/login" onClick={logged}>
            Zaloguj się
          </Link> : null}
        </li>
        <li>
          <Link className="navigation__item" to="/" onClick={() => setIsShow(false)}>
            <img className="navigation__icon" src={home} alt="home" />
          </Link>
        </li>
      </ul>
    </header>
  );
};

export default Navigation;
