import axios from 'axios';

export const authorizedAxios = (cookies : any) => {

    const instance = axios.create({
        // @ts-ignore
    baseURL: global.REACT_APP_API_URL,
    headers: {
      'Authorization': `Bearer ${cookies.token}`,
      'Content-Type': 'application/json',
    },
  });
  return instance;
};

export const createAxiosInstance = () => {
    const instance = axios.create({
        // @ts-ignore
      baseURL: global.REACT_APP_API_URL,
    });
    return instance;
};
