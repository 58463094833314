/* eslint-disable react-hooks/exhaustive-deps */
import components from "../../componentList";
import { useState } from "react";
import axios from "axios";
import { useAPI } from "../../context";
import useNav from "../../hooks/useNav";
import jwt_decode from 'jwt-decode';

const Login = () => {

    const { setCookie, setDataUser, dataUser } = useAPI();
    const { Link, Loader } = components;
    useNav();

    const [login, setLogin] = useState({email : "", password : ""})
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState("");

    const [netError, setNetError] = useState(false);

    const { email, password } = login;

    const logged = () => {

        setLoading(true);

        if (email !== "" && password !== "") {
            const data = { email : email, password : password }
            // @ts-ignore
            axios.post(`${global.REACT_APP_API_URL}/user/login`, data)
              .then((response : any) => {
    
                const convert = jwt_decode<any>(response.data.token)
    
                setDataUser({
                    ...dataUser,
                    name: dataUser.name = convert.name,
                    email: dataUser.email = convert.email,
                    password: dataUser.password = "" 
                })
                setLoading(false)
                setCookie('token', response.data.token, { path: '/' });
              })
              .catch((error : any) => {
                if (error.status === 500) {
                    setNetError(true);
                }
                setLoading(false);
                setInfo("Zły login lub hasło")
                setDataUser({
                    ...dataUser,
                    password: dataUser.password = "" 
                })
              });
        }
        else {
            setInfo("Nie udało się zarejestrować");
            setLoading(false);
            setDataUser({
                ...dataUser,
                password: dataUser.password = "" 
            })
        }
        
    }

    const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const value = evt.target.value;
        setLogin({...login,[evt.target.name]: value});
    }

    return (
        <div className="login">
            {!netError ? 
            <>
            <h1 className="login__title">{info === "" ? "Logowanie" : info}</h1>
            <div className="login__wrapper">
            {loading ? <Loader /> : null}
                {!loading ? <form className="login__form" onSubmit={(event) => event.preventDefault()}>
                    <input onChange={(e) => handleChange(e)} className="login__item" placeholder="Email" type="text" value={email} name="email"/>
                    <input onChange={(e) => handleChange(e)} className="login__item" placeholder="Hasło" type="password" value={password} name="password"/>
                    <Link className="login__linkTwo" to="/passwordReminder">Nie pamiętam hasła</Link>
                    <button className="login__item login__item--submit" onClick={logged}>Zaloguj się</button>
                </form> : null}
                {!loading ? <p className="login__text">Nie posiadasz jeszcze konta ?</p> : null}
                {!loading ? <Link className="login__link" to="/register">Zarejestruj się</Link> : null}
            </div>
            </> : <p className="login__error">Coś poszło nie tak spróbuj ponownie</p>}
        </div>
    );
};

export default Login;
