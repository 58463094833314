import Tab from "../common/tab";
import Loader from "../common/loader";
import Spinner from "../common/spinner";

import Home from "../pages/home";
import JoinTheRenovation from "../pages/joinTheRenovation";
import Creator from "../pages/creator";
import Repairs from "../pages/repairs";
import PasswordReminder from "../pages/passwordReminder";
import Register from "../pages/register";
import Login from "../pages/login";

import ModalDescription from "../modals/modalDescription";
import ModalCalendar from "../modals/modalCalendar";
import ModalImage from "../modals/modalImage";
import ModalEditRepair from "../modals/modalEditRepair";

import Navigation from "../components/navigation";
import Profile from "../components/profile";
import EditProfile from "../components/editProfile";
import Language from "../components/language";
import Information from "../components/information";
import Subscription from "../components/subscription";
import Payment from "../components/payment";
import Section from "../components/section";
import ResetPassword from "../components/resetPassword";
import Authorization from "../components/authorization";
import NewPassword from "../components/newPassword";
import Renovation from "../components/renovation";
import User from "../components/user";
import PhotoUpload from "../components/photoUpload";
import Messenger from "../components/messenger";

import { Link, useNavigate, useLocation } from "react-router-dom";

const components = {
  Navigation,
  Home,
  Register,
  Login,
  Link,
  Profile,
  useNavigate,
  useLocation,
  Creator,
  Repairs,
  EditProfile,
  Tab,
  Language,
  Information,
  Subscription,
  Payment,
  Section,
  ModalEditRepair,
  JoinTheRenovation,
  PasswordReminder,
  ResetPassword,
  Authorization,
  NewPassword,
  Spinner,
  Renovation,
  User,
  ModalDescription,
  Loader,
  PhotoUpload,
  ModalImage,
  ModalCalendar,
  Messenger,
};

export default components;
