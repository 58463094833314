/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import components from "../../componentList";
import { useAPI } from "../../context";

const Home = () => {

  const { Link, Profile, User } = components;

  const { cookies, nameLS } = useAPI();
  
  return (
    !cookies.token ? 
    nameLS === "" ?
    <div className="home">
      <div className="home__wrapper">
        <div className="home__gradient" />
        <Link className="home__link" to="/login">Stwórz remont</Link>
        <Link className="home__link" to="/joinTheRenovation">Dołącz do remontu</Link>
      </div>
    </div> : <User /> : <Profile />
  );
};

export default Home;
