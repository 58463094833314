import Modal from "react-modal";
import { customStyles } from "../../utils/customStyles";

const ModalEditRepair = ({ isModal, closeModal, document }: any) => {

  const { description, base64Data, name } = document

  Modal.setAppElement("#root");

  return (
    <Modal isOpen={isModal} style={customStyles} onRequestClose={closeModal}>
      <div className="modalImage">
            <div className="modalImage__box">
                <p className="modalImage__description">{description}</p>
                <img className="modalImage__image" src={base64Data} alt={name}/>
            </div>
      </div>
    </Modal>
  );
};

export default ModalEditRepair;