/* eslint-disable react-hooks/exhaustive-deps */
import images from "../../imagesList";
import { useState, useEffect } from "react";
import axios from "axios";
import components from "../../componentList";
import { useAPI } from "../../context";
import useRemontList from "../../hooks/useRemontList";
import Calendar from 'react-calendar';


const Renovation = ({ setIsShow, isShow }: any) => {

  const { ModalDescription, Loader, PhotoUpload } = components;
  const { chat } = images;

  const { dataRemont, selectedFile, setSelectedFile, base64Data, setBase64Data, setArrayDescription,
  noticeBoard, setNoticeBoard, schedule, setSchedule, specification, setSpecification } = useAPI();

  const [isModal, setIsModal] = useState<boolean>(false);
  const [admin, setAdmin] = useState<any>({
    noticeBoard: "",
    specification: "",
    schedule: "",
  });

  useRemontList();

  const [inderError, setIndexError] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setIsError] = useState(false);
  const [index, setIndex] = useState<any>(null);
  const [characteristic, setCharacteristic] = useState<any>(null);
  const [loadCharacteristic, setLoadCharacteristic] = useState<any>(null);
  const [endpoint, setEndpoint] = useState<any>("");

  const [isCheck, setIsCheck] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [data, setData] = useState<any>([]);
  const [checkDate, setCheckDate] = useState<any>([]);

  const date = (dateString: any) => {
    
    const dateObject = new Date(dateString);

    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, "0");
    const day = String(dateObject.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  };

  const uploadNotice = (isPrivate : any) => {

  setIndexError(0);
  setLoading(true);

  const nameImg = selectedFile === null ? null : selectedFile.name  

  const data = { text : admin.noticeBoard, key : dataRemont.key, private : isPrivate, base64Data : base64Data, nameImg : nameImg } 

    axios
        // @ts-ignore
      .post(`${global.REACT_APP_API_URL}/post/noticeBoard`, data)
      .then((response: any) => {
        setAdmin({ ...admin, noticeBoard: "" });
        setLoading(false);
        setSelectedFile(null);
        setIndex(null);
        setBase64Data(null);
        setNoticeBoard((oldArray: any) => [
          ...oldArray,
          { 
            ...data, 
            uuid : response.data.uuid,
            base64Data : response.data.base64Data,
            description : admin.noticeBoard
          }
        ]);
      })
      .catch((error: any) => {
        setIsError(true);
        setLoading(false);
        setSelectedFile(null);
        setIndex(null);
        setBase64Data(null);
      });
  };

  const uploadSpecification = (isPrivate: any) => {

    setIndexError(1);
    setLoading(true);

    const nameImg = selectedFile === null ? null : selectedFile.name  
    const data = { text : admin.specification, key : dataRemont.key, private : isPrivate, base64Data : base64Data, nameImg : nameImg } 

    axios
        // @ts-ignore
      .post(`${global.REACT_APP_API_URL}/post/specification`, data)
      .then((response: any) => {
        setAdmin({ ...admin, specification: "" });
        setLoading(false);
        setSelectedFile(null);
        setIndex(null);
        setBase64Data(null);
        setSpecification((oldArray: any) => [
          ...oldArray,
          { 
            ...data, 
            uuid : response.data.uuid,
            base64Data : response.data.base64Data,
            description : admin.noticeBoard
          }
        ]);
      })
      .catch((error: any) => {
        setIsError(true);
        setLoading(false);
        setSelectedFile(null);
        setIndex(null);
        setBase64Data(null);
      });
  };

  const uploadSchedule = (isPrivate: any) => {

    if (isCheck) {
      setIndexError(2);
      setLoading(true);

    const data = { text : admin.schedule, key : dataRemont.key, private : isPrivate, base64Data : null, nameImg : null, currentDate : checkDate } 

    axios
        // @ts-ignore
      .post(`${global.REACT_APP_API_URL}/post/schedule`, data)
      .then((response: any) => {
        setAdmin({ ...admin, schedule: "" });
        setLoading(false);
        setSelectedFile(null);
        setIndex(null);
        setBase64Data(null);
        setSchedule((oldArray: any) => [
          ...oldArray,
          { 
            ...data, 
            uuid : response.data.uuid,
            description : admin.noticeBoard
          }
        ]);
        setIsCheck(false);
        setSelectedDate(null);
        setData([]);
      })
      .catch((error: any) => {
        setIsError(true);
        setLoading(false);
        setSelectedFile(null);
        setIndex(null);
        setBase64Data(null);
        setIsCheck(false);
        setSelectedDate(null);
        setData([]);
      });
    }
  };

  const handleChange = (evt: any) => {
    const value = evt.target.value;
    setAdmin({ ...admin, [evt.target.name]: value });
  };

  const closeModal = () => {
    setIsModal(false);
  };

  const openModal = async (item: any, endpoint : any) => {

    setEndpoint(endpoint)

    try {
      const instance = axios.create({
        withCredentials: true,
      });
      // @ts-ignore
      const responses = await Promise.all(item.map((url : any) => instance.get(`${global.REACT_APP_API_URL}/post/${endpoint}/${url.uuid}`)));
      const newNoticeBoardData = responses.map((response : any) => ({...response.data}));

      setArrayDescription(newNoticeBoardData)
      setIsModal(true);

    } catch (error) {
      console.error('Błąd podczas pobierania danych użytkownika: ', error);
    }
  };

  useEffect(() => {
    if (isShow) {
      const fetchData = async () => {
        try {
          const instance = axios.create({
            withCredentials: true,
          });
          // @ts-ignore
          const responses = await Promise.all(dataRemont.characteristic.map((url : any) => instance.get(`${global.REACT_APP_API_URL}/post/characteristic/${url.uuid}`)));
          const newNoticeBoardData = responses.map((response) => ({...response.data}));

          setCharacteristic(newNoticeBoardData)
          setLoadCharacteristic(true)

        } catch (error) {
          console.error('Błąd podczas pobierania danych użytkownika: ', error);
        }
      }
    
    fetchData();
    }
  }, [isShow]);

  const customTileClassName = ({ date } : any) => {

    const dateString = date.toISOString().split('T')[0];
    const foundDay = data.find((item : any) => item.day.toISOString().split('T')[0] === dateString);

    if (foundDay) {
      return 'highlight-day';
    }
    return null;
  };

  const handleDateClick = (date : any) => {

    const dateObject = new Date(date);

    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, '0');
    const day = String(dateObject.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    setIsCheck(true);
    setCheckDate(formattedDate)
    const foundDayIndex = data.findIndex((item : any) => item.day.toISOString().split('T')[0] === date.toISOString().split('T')[0]);

    if (foundDayIndex !== -1) {

      setData((prevData : any) => prevData.filter((item : any, index : any) => index !== foundDayIndex));
      setSelectedDate(null);
    } else {

      if (selectedDate) {
        setData((prevData : any) => prevData.filter((item : any) => item.day.toISOString().split('T')[0] !== selectedDate.toISOString().split('T')[0]));
      }

      setData((prevData : any) => [...prevData, { day: date, text: "text" }]);
      setSelectedDate(date);
    }
  };

  return (
    <div className="renovation">
      {!error ? (
        <>
          <ModalDescription {...{ isModal, closeModal, endpoint }} />
          <p className="renovation__titleMain">{dataRemont.name}</p>
          <div className="renovation__wrapperThree">
            <h2 className="renovation__title">Prace remontowe</h2>
            {loadCharacteristic ? characteristic.map((item: any, index: number) => (
              <div className="renovation__container" key={index}>
                <p className="renovation__text">{item.description}</p>
                <p className="renovation__name">
                  {item.nameUser} - {item.specialization}
                </p>
                <p className="renovation__name">{date(item.currentDate)}</p>
                {item.base64Data === null ? null : <img className="renovation__image" src={item.base64Data} alt={item.name} />}
              </div>
            )) : <Loader />}
          </div>
          <div className="renovation__wrapper">
            {loading && inderError === 0 ? <Loader /> : null}
            <button
              onClick={() => openModal(noticeBoard, "noticeBoard")}
              className="renovation__titleButton"
            >
              Tablica ogłoszeń
            </button>
            <textarea
              className="renovation__value"
              placeholder="Co chcesz dodać ?"
              value={admin.noticeBoard}
              name="noticeBoard"
              onChange={(e) => handleChange(e)}
            />
            <div className="renovation__wrapperButton">
              <button
                onClick={() => uploadNotice(false)}
                className={admin.noticeBoard.length === 0 ? "renovation__buttonAdd" : "renovation__buttonAdd renovation__buttonAdd--active"}
                disabled={admin.noticeBoard.length === 0 ? true : false}
              >
                Udostępnij
              </button>
              <button
                onClick={() => uploadNotice(true)}
                className={admin.noticeBoard.length === 0 ? "renovation__buttonAdd renovation__buttonAdd--color" : "renovation__buttonAdd--color renovation__buttonAdd renovation__buttonAdd--active"}
                disabled={admin.noticeBoard.length === 0 ? true : false}
              >
                Zachowaj
              </button>
            </div>
            <PhotoUpload index={0} myIndex={index} setIndex={setIndex} />
          </div>
          <div className="renovation__wrapper">
            {loading && inderError === 2 ? <Loader /> : null}
            <button
              onClick={() => openModal(schedule, "schedule")}
              className="renovation__titleButton"
            >
              Harmonogram
            </button>
            <textarea
              className="renovation__value"
              placeholder="Co chcesz dodać ?"
              value={admin.schedule}
              name="schedule"
              onChange={(e) => handleChange(e)}
            />
            <Calendar
              value={new Date()}
              onChange={handleDateClick}
              tileClassName={customTileClassName}
            />
            <div className="renovation__wrapperButton">
              <button
                onClick={() => uploadSchedule(false)}
                className={admin.schedule.length === 0 || !isCheck ? "renovation__buttonAdd" : "renovation__buttonAdd renovation__buttonAdd--active"}
                disabled={admin.schedule.length === 0 || !isCheck ? true : false}
              >
                Udostępnij
              </button>
              <button
                onClick={() => uploadSchedule(true)}
                className={admin.schedule.length === 0 || !isCheck ? "renovation__buttonAdd renovation__buttonAdd--color" : "renovation__buttonAdd--color renovation__buttonAdd renovation__buttonAdd--active"}
                disabled={admin.schedule.length === 0 || !isCheck ? true : false}
              >
                Zachowaj
              </button>
            </div>
          </div>
          <div className="renovation__wrapper">
            {loading && inderError === 1 ? <Loader /> : null}
            <button
              onClick={() => openModal(specification, "specification")}
              className="renovation__titleButton"
            >
              Specyfikacja
            </button>
            <textarea
              className="renovation__value"
              placeholder="Co chcesz dodać ?"
              value={admin.specification}
              name="specification"
              onChange={(e) => handleChange(e)}
            />
            <div className="renovation__wrapperButton">
              <button
                onClick={() => uploadSpecification(false)}
                className={admin.specification.length === 0 ? "renovation__buttonAdd" : "renovation__buttonAdd renovation__buttonAdd--active"}
                disabled={admin.specification.length === 0 ? true : false}
              >
                Udostępnij
              </button>
              <button
                onClick={() => uploadSpecification(true)}
                className={admin.specification.length === 0 ? "renovation__buttonAdd renovation__buttonAdd--color" : "renovation__buttonAdd--color renovation__buttonAdd renovation__buttonAdd--active"}
                disabled={admin.specification.length === 0 ? true : false}
              >
                Zachowaj
              </button>
            </div>
            <PhotoUpload index={2} myIndex={index} setIndex={setIndex} />
          </div>
          <span className="renovation__block" />
          <footer className="renovation__footer">
            <div className="renovation__box">
              <p className="renovation__text">Napisz do</p>
              <img className="renovation__icon" src={chat} alt="chat" />
            </div>
            <div className="renovation__box">
              <p className="renovation__text">Członkowie</p>
              <p className="renovation__text">{dataRemont.members.length}</p>
            </div>
          </footer>
        </>
      ) : (
        <div className="repairs__error">
          <p>Coś poszło nie tak spróbuj póżniej</p>
        </div>
      )}
    </div>
  );
};

export default Renovation;
