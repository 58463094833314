const Tab = ({dispatch, title} : any) => {

    return (
        <div className="tab">
            <button className="tab__back" onClick={dispatch}>&lt;</button>
            <p className="tab__name">{title}</p>
        </div>
    )
}

export default Tab;