/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import axios, { AxiosError } from 'axios';
import { useAPI } from "../context";

const useRemontList = () => {

    const [remont, setRemont] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [error, setIsError] = useState(false);
    const [getList, setGetList] = useState(false);

    const { contextLS, setNoticeBoard, setSchedule, setSpecification } = useAPI();

    useEffect(() => {
        const fetchData = async () => {
            try {
              const instance = axios.create({
                withCredentials: true,
              });
                // @ts-ignore
              const response = await instance.get(`${global.REACT_APP_API_URL}/in/remont/${contextLS}`);
              
              setNoticeBoard(response.data.remont.noticeBoard)
              setSchedule(response.data.remont.schedule)
              setSpecification(response.data.remont.specification)

              setRemont(response.data.remont)
              setLoading(true)
              
            } catch (error) {
              if (axios.isAxiosError(error)) {

                const axiosError = error as AxiosError; 
            
                if (axiosError.response?.status === 404) {
                  setLoading(true)
                } else {
                  setIsError(true)
                }
              }
            }
          }
    
        fetchData();
      }, [getList]);
      return { remont, loading, error, setIsError, setGetList }
}

export default useRemontList;
