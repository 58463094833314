import Modal from "react-modal";
import { useAPI } from "../../context";
import axios from "axios";
import images from "../../imagesList";
import { customStyles } from "../../utils/customStyles";

const ModalDescription = ({ isModal, closeModal, endpoint }: any) => {

  const { arrayDescription, contextLS, setSpecification, setSchedule, setNoticeBoard, setArrayDescription } = useAPI();

  const { trash_bin } = images;

  Modal.setAppElement("#root");

  const removeRemont = (uuidToRemove: any) => {

    axios
        // @ts-ignore
      .delete(`${global.REACT_APP_API_URL}/post/${endpoint}/${contextLS}/${uuidToRemove}`)
      .then((response: any) => {
        if (endpoint === "noticeBoard") {
          setNoticeBoard((prevCharacteristic : any) =>
            prevCharacteristic.filter((item : any) => item.uuid !== uuidToRemove)
          );
        }
        if (endpoint === "schedule") {
          setSchedule((prevCharacteristic : any) =>
            prevCharacteristic.filter((item : any) => item.uuid !== uuidToRemove)
          );
        }
        if (endpoint === "specification") {
          setSpecification((prevCharacteristic : any) =>
            prevCharacteristic.filter((item : any) => item.uuid !== uuidToRemove)
          );
        }
        setArrayDescription((prevCharacteristic : any) =>
            prevCharacteristic.filter((item : any) => item.uuid !== uuidToRemove)
        );
      })
      .catch((error: any) => {
        console.error('Błąd', error);
      });
  };

  const compilerDate = (date : any) => {
    const dateObj = new Date(date);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');  
    const day = String(dateObj.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate
  }

  const reversedArray = [...arrayDescription].reverse();

  return (
    <Modal isOpen={isModal} style={customStyles} onRequestClose={closeModal}>
      <div className="modalDescription">
        {arrayDescription.length === 0 ? <p className="modalDescription__text">Nie posiadasz żadnych wpisów</p> : reversedArray.map((item : any, index : any) => (
          <div className="modalDescription__wrapper" key={index}>
            <div className="modalDescription__box">
                <p className="modalDescription__description">{item.description}</p>
                {item.base64Data === null ? null : <img className="renovation__image" src={item.base64Data} alt={item.name} />}
                <p>{compilerDate(item.currentDate)}</p>
            </div>
            <button className="modalDescription__buttonRemove" onClick={() => removeRemont(item.uuid)}>
                <img className="modalDescription__icon" src={trash_bin} alt="trash_bin"/>
            </button>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default ModalDescription;
