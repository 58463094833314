import React from 'react';
import Dropzone from 'react-dropzone';
import { useAPI } from "../../context";

const PhotoUpload = ({ index, myIndex, setIndex }: any) => {
  
  const { selectedFile, setSelectedFile, base64Data, setBase64Data } = useAPI();

  const handleDrop = (acceptedFiles: any) => {
    const file = acceptedFiles[0];

    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result as string; 
      setSelectedFile(file);
      setBase64Data(base64String);
      setIndex(index);
    };

    reader.readAsDataURL(file);
  };

  return (
    <div className="photoUpload">
      <Dropzone onDrop={handleDrop}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} style={{ border: '2px dashed #ccc', padding: '20px' }}>
            <input {...getInputProps()} />
            <p>Upuść plik tutaj lub kliknij, aby wybrać zdjęcie</p>
          </div>
        )}
      </Dropzone>
      {base64Data && index === myIndex && selectedFile !== null ? (
        <div className="photoUpload__wrapper">
          <p className="photoUpload__text">Wybrano zdjęcie: {selectedFile.name}</p>
        </div>
      ) : null}
    </div>
  );
};

export default PhotoUpload;