import { useEffect, useState } from "react"
import { LOCAL_STORAGE } from "../enums/localStorage";

const useLocalstorage = () => {
  
    const [contextLS, setContextLS] = useState<any>(window.localStorage.getItem(LOCAL_STORAGE.USER_ID) || '')
    const [nameLS, setNameLS] = useState<any>(window.localStorage.getItem(LOCAL_STORAGE.NAME_ID) || '')
    const [specializationLS, setSpecializationLS] = useState<any>(window.localStorage.getItem(LOCAL_STORAGE.SPECIALIZATION_ID) || '')
    const [authorizationLS, setAuthorizationLS] = useState<any>(window.localStorage.getItem(LOCAL_STORAGE.AUTHORIZATION_ID) || '')

    useEffect(() => {
        window.localStorage.setItem(LOCAL_STORAGE.USER_ID, contextLS)
        window.localStorage.setItem(LOCAL_STORAGE.NAME_ID, nameLS)
        window.localStorage.setItem(LOCAL_STORAGE.SPECIALIZATION_ID, specializationLS)
        window.localStorage.setItem(LOCAL_STORAGE.AUTHORIZATION_ID, authorizationLS)
        //localStorage.clear()
    })
  
    return { contextLS, setContextLS, nameLS, setNameLS, specializationLS, setSpecializationLS, setAuthorizationLS, authorizationLS }

}

export default useLocalstorage;