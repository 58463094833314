/* eslint-disable react-hooks/exhaustive-deps */
import { useAPI } from "../context";
import { useEffect } from "react";
import components from "../componentList";

const useNav = () => {

    const { useNavigate } = components;

    const { cookies } = useAPI();
    const navigate = useNavigate();
   
    useEffect(() => { 
        if (cookies.token) {
            navigate("/")
        }
    },[cookies])

}

export default useNav;