import components from "../../componentList";

const Section = ({title, dispatch, children} : any) => {

    const { Tab } = components;

    return (
        <div className="section">
            <Tab title={title} dispatch={dispatch}/>
            <div className="section__wrapper">
                {children}
            </div>
        </div>
    )
}

export default Section;