/* eslint-disable react-hooks/exhaustive-deps */
import { useAPI } from "../../context";
import images from "../../imagesList";
import components from "../../componentList";
import { useState, useEffect } from "react";
import useProfile from "../../hooks/useProfile";
import { fetchRepairsList } from "../../api/repairs/repairsApi";
import { getError } from "../../api/helpers/getError/getError";

const Repairs = () => {
  const { setting, verified } = images;
  const { ModalEditRepair, Spinner, Renovation } = components;

  const {
    list,
    setRemont,
    remont,
    cookies,
    setList,
    loadingProfile,
    dataUser,
    setDataRemont,
    isShow,
    setIsShow,
    setContextLS,
  } = useAPI();
  useProfile();

  const [num, setNum] = useState<number | null>(null);
  const [controll, setControll] = useState<boolean>(false);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [indexRemont, setIndexRemont] = useState<number>(0);
  const [isLoadingRemonts, setIsLoadingRemonts] = useState<boolean>(false);
  const [error, setIsError] = useState<boolean>(false);

  useEffect(() => {
    if (cookies.token && dataUser.id) {
      const fetchData = () => {
        fetchRepairsList(cookies)
          .then((data) => {
            const fetchedList = data.list.filter((item : any) => item.id === dataUser.id);
            const newSortedArray = fetchedList.slice().sort((a : any, b : any) => {
              return a.completed && !b.completed ? -1 : 0;
            });
            setList(newSortedArray);
            setIsLoadingRemonts(true);
          })
          .catch((error) => {
            getError(error.status);
          });
      };
  
      fetchData();
    }
  }, [cookies.token, dataUser.id]);

  const showDescription = (index: number) => {
    setNum((prevNum) => (prevNum = index));
    if (index === num) {
      setControll(!controll);
    } else {
      setControll(false);
    }
  };

  const editRemont = (item: any, index: number) => {
    setRemont({
      ...remont,
      name: (remont.name = item.name),
      description: (remont.description = item.description),
      id: (remont.id = item._id),
    });
    setIsModal(true);
    setIndexRemont(index);
  };

  const closeModal = () => {
    setIsModal(false);
  };

  const showRemont = (index: number) => {
    setContextLS(list[index].key);
    setDataRemont(list[index]);
    setIsShow(true);
  };

  return (
    <div className="repairs">
      {cookies.token ? (
        isLoadingRemonts && loadingProfile ? (
          <>
            <ModalEditRepair
              {...{ indexRemont, isModal, closeModal, error, setIsError }}
            />
            {!isShow ? (
              list.length === 0 ? (
                <p className="repairs__text">Nie posiadasz żadnych remontów</p>
              ) : (
                list.map((item: any, index: any) => (
                  <div className="repairs__container" key={index}>
                    {item.completed ? (
                      <img className="repairs__iconApproved" src={verified} alt="verified"/>
                    ) : null}
                    <div className={num === index && !controll ? "repairs__wrapperActive show": "repairs__wrapper"}>
                      <div className="repairs__box">
                        <button className="repairs__buttonExpand" onClick={() => showDescription(index)}>
                          &gt;
                        </button>
                        <button className="repairs__buttonShow" onClick={() => showRemont(index)}>
                          {item.name}
                        </button>
                        <button className="repairs__buttonEdit" onClick={() => editRemont(item, index)}>
                          <img className="repairs__icon" src={setting} alt="setting"/>
                        </button>
                      </div>
                      <p className="repairs__description">{item.description}</p>
                    </div>
                  </div>
                ))
              )
            ) : (
              <Renovation {...{ setIsShow, isShow }} />
            )}
          </>
        ) : error ? (
          <div className="repairs__error">
            <p>Coś poszło nie tak spróbuj póżniej</p>
          </div>
        ) : (
          <Spinner />
        )
      ) : (
        <div className="repairs__wrap">
          <p className="repairs__textTwo">Nie posiadasz żadnych remontów</p>
        </div>
      )}
    </div>
  );
};

export default Repairs;
